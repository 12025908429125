
import {selector,} from 'recoil';
import krilBalState from '../atoms/krilbal';

const getKrilBal = selector({
    key: 'krilBalStateGetter', // unique ID (with respect to other atoms/selectors)
    get: ({get}) => {
      const text = get(krilBalState);
  
      return text;
    },
  });

  export default getKrilBal;