import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import {LockClosedIcon,LockOpenIcon} from "@heroicons/react/24/solid"
import { useEffect } from 'react'
import { useState } from 'react'
import { getConfig, getAbis } from "../helpers"

import Web3 from "web3";
import getWalletState from '../selectors/wallets'
import {
  useRecoilValue,
} from 'recoil';
import { toast } from 'react-hot-toast'
const config = getConfig();
const abis = getAbis();

export default function ApproveModal({isOpen,setIsOpen}) {

  const [isNftApproved,setIsNftApproved] = useState(false);
  const [isKrilApproved,setIsKrilApproved] = useState(false);
  const walletInfo = useRecoilValue(getWalletState);

  useEffect(()=>{
    async function approvalChecker(){
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(abis["nft"], config["nft"])
      const erc20_contract = new web3.eth.Contract(abis["kril"], config["krill"])
      setIsNftApproved(await contract.methods.isApprovedForAll(walletInfo.account,config["staking"]))
    }
    approvalChecker();

  },[walletInfo.account])

  async function approveStakingContract(){

        
    const web3Instance = new Web3(window.ethereum);

    const contractInstance = new web3Instance.eth.Contract(abis.nft,config.nft);
    
    
    const krilContract = new web3Instance.eth.Contract(abis.kril,config.krill);
    
    
    const isApprovedForAll = await contractInstance.methods.isApprovedForAll(walletInfo.account,config.stake).call();
    console.log("Here")
    const isAllowanced = await krilContract.methods.allowance(walletInfo.account,config.stake).call();
    console.log(isAllowanced)
    
    if(!isApprovedForAll){
        const nftPromise =  contractInstance.methods.setApprovalForAll(config.stake,true).send({from:walletInfo.account});
        await toast.promise(nftPromise,{
          success:"Successfully Approved NFT",
          error: (err) => `Error happened: ${err.toString()}`,
          loading: 'Approving NFT...',
        })
    
      }
    if(isAllowanced !== "115792089237316195423570985008687907853269984665640564039457584007913129639935"){
        const krilApprovePromise =  krilContract.methods.approve(config.stake,"115792089237316195423570985008687907853269984665640564039457584007913129639935").send({from:walletInfo.account})
        await toast.promise(krilApprovePromise,{
          success:"Successfully Approved Kril",
          error: (err) => `Error happened: ${err.toString()}`,
          loading: 'Approving Kril...',
        })

      }
    

}


  
  function closeModal() {
    setIsOpen(false)
  }

  
  return (
    
     
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Approve Kril and Sushi to Staking Contract
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className='flex bg-pink-300 p-2 rounded'>
                        <h1 className='text-xl'>
                            Sushi Approved : 
                        </h1>
                        {isNftApproved ? <LockOpenIcon className="h-5 ml-auto  text-blue-500"/> : <LockClosedIcon className='h-5 ml-auto text-blue-500'/>}


                    </div>
                    <div className='flex mt-5 bg-pink-300 p-2 rounded'>
                        <h1 className='text-xl'>
                            Kril Approved : 
                        </h1>
                        {isKrilApproved ? <LockOpenIcon className="h-5 ml-auto text-blue-500"/> : <LockClosedIcon className='h-5 ml-auto text-blue-500'/>}
                    </div>
                  </div>
                  
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={approveStakingContract}
                    >
                      Approve
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    
  )
}
