
import {
  atom,
} from 'recoil';

const walletState = atom({
    key: 'walletState', // unique ID (with respect to other atoms/selectors)
    default: {"account":"","isConnected":false}, // default value (aka initial value)
  });

export {walletState};