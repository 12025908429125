
import {selector,} from 'recoil';
import nftsState from '../atoms/nfts';
  

const getNftsState = selector({
    key: 'nftsStateGetter', // unique ID (with respect to other atoms/selectors)
    get: ({get}) => {
      const text = get(nftsState);
  
      return text;
    },
  });

  export default getNftsState;