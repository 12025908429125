import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { getConfig, getAbis, normalizeBalance, fetchNfts, getNftsLevel ,getBaseURIBYLevel} from "../helpers"
import Web3 from "web3";
import getWalletState from '../selectors/wallets'

import {
    useRecoilValue,
} from 'recoil';
import { toast } from 'react-hot-toast'
import getNftsState from '../selectors/nfts'
import setRecoilNfts from "../atoms/nfts";
const config = getConfig();
const abis = getAbis();

export default function BurningIncinator({ mode, isOpen, setIsOpen }) {
    

    const nfts = useRecoilValue(getNftsState);
    const walletInfo = useRecoilValue(getWalletState);

    const [selectedNfts, setSelectedNfts] = useState([]);

    function addSelectionBorder() {
        let nftBoxes = document.getElementsByClassName("nftBorder");
        for (let i = 0; i < nftBoxes.length; i++) {
            const element = nftBoxes[i];
            if (element.classList.contains("image-active")) {
                element.classList.remove("image-active");
            }
        }
        nftBoxes = document.getElementsByClassName("nftBorder");
        for (let i = 0; i < nftBoxes.length; i++) {
            const element = nftBoxes[i];
            const id = element.id;
            console.log(typeof id, selectedNfts)
            if (nftIsSelected(id)) {

                console.log("Adding")

                element.classList.add("image-active");
            }
        }

    }

    useEffect(() => {
        console.log(selectedNfts);
        addSelectionBorder();

    }, [selectedNfts])


    function nftIsSelected(nft) {
        var strArr = selectedNfts.map(function (e) { return e.toString() });

        return strArr.includes(nft.toString());
    }

    function toogleNftSelection(nft) {

        function arrayRemove(arr, value) {

            return arr.filter(function (ele) {
                return ele != value;
            });
        }

        if (nftIsSelected(nft)) {
            console.log("NFT is already selected , now removing")
            const newNfts = selectedNfts;
            setSelectedNfts(arrayRemove(newNfts, nft))
        }
        else {
            if (selectedNfts.length < mode) {
                setSelectedNfts([...selectedNfts, nft]);
            }
            else {
                toast.error(`${mode} Nfts Selected , Remove selection to add another`)
            }

        }
    }





    async function burnandUpgrade() {

        let price = config.levelThreeUpgradePrice;
        if (mode === 2) {
            price = config.levelThreeAltUpgradePrice;
        }
        const web3 = new Web3(window.ethereum);
        const nft_contract = new web3.eth.Contract(abis.nft, config.nft);
        const approvedForAll = await nft_contract.methods.isApprovedForAll(walletInfo.account, config.stake);
        const staking_contract = new web3.eth.Contract(abis.staking, config.stake);
        if (!approvedForAll) {

            const approvalPromise = nft_contract.methods.setApprovalForAll(config.stake, true).send({ from: walletInfo.account });
            await toast.promise(approvalPromise, {
                loading: "Approving Staking Contract...",
                error: "Error Approving Contract",
                "success": "Successfully Approved",

            })

        }



        const upgradePromise = staking_contract.methods.burnSushiForKril(selectedNfts).send({ from: walletInfo.account })

        await toast.promise(upgradePromise, {
            loading: 'Burning Nfts',
            success: "Successfully Burned",
            error: "Error",
        })
        const account = walletInfo.account;
        async function levelFetcher() {
            return await fetchNfts(account).then(async (data) => {
                console.log(data)
                const levels = [];
                for (let i = 0; i < data.length; i++) {
                    const nft = data[i];
                    console.log("nfy", nft)
                    const level = await getNftsLevel(nft);
                    levels.push({ "id": parseInt(nft), "level": parseInt(level) })
                }
                setRecoilNfts(levels)

            })
        }
        const promise = levelFetcher();
        toast.promise(promise, {
            loading: 'Fetching NFTS',
            success: "Success",
            error: "Error Fetching Nfts , Reload Website!",
        })

    }


    return (


        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => {
                setIsOpen(false);
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-transaparent p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    Burning Sushi for Kril
                                </Dialog.Title>
                                <div className="w-full">
                                    {/* <div className="wallet-info flex flex-col gap-4 p-[22px] xl:mt-0">
                                        <div>
                                            <h1>Burn any {mode} Nfts to Upgrade to Mythic ({mode} NFT + {mode === 5 ? "99" : "33"} Kril)</h1>
                                        </div>
                                    </div> */}
                                    <div className="wallet-btns-container">

                                        {walletInfo.isConnected &&
                                            <>
                                                <div className="pt-8">
                                                    <div className="images min-h-[420px] max-h-[420px] pl-[3%] flex flex-wrap content-start gap-[5%] overflow-y-auto">

                                                        <>
                                                            {nfts.map((nft) => {
                                                                if(nft.isStaked === false){
                                                                    return (
                                                                        <div className="image w-[45%] mb-[5%]" key={nft.id} onClick={() => { toogleNftSelection(nft.id) }} >
                                                                            <img src={`${getBaseURIBYLevel(nft.level)}${nft.id}.png`} alt="" id={nft.id} className={`nftBorder w-[100%] h-[35vw] max-h-[200px] inline sm:max-h-[250px] md:max-h-[200px]`} />
                                                                            <p className="image-caption">Psyco Susi #{nft.id}</p>
                                                                        </div>
                                                                    )

                                                                }
                                                                


                                                            })}
                                                        </>

                                                    </div>
                                                </div>
                                                <div className="wallet-btns-container-bottom flex flex-col gap-2 md:flex-row md:justify-end">
                                                    <button className="btn w-full " disabled={selectedNfts.length === 0} onClick={
                                                        () => {
                                                            burnandUpgrade();
                                                        }
                                                    }>Burn & Earn</button>

                                                </div>
                                            </>
                                        }

                                    </div>
                                </div>


                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>

    )
}
