
import {selector,} from 'recoil';
import { walletState } from '../atoms/wallets';
  

const getWalletState = selector({
    key: 'walletStategetter', // unique ID (with respect to other atoms/selectors)
    get: ({get}) => {
      const text = get(walletState);
  
      return text;
    },
  });

  export default getWalletState;