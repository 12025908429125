import React from 'react'
import Web3 from 'web3';
import toast from "react-hot-toast"
import { useState } from 'react';
import LevelConnect from '../levelConnect'
import getWalletState from '../../selectors/wallets'
import getNftsState from '../../selectors/nfts';
import { getNftsLevel, getConfig, getAbis, fetchNfts, normalizeBalance,getBaseURIBYLevel} from '../../helpers';
import { walletState } from '../../atoms/wallets';
import nftsState from '../../atoms/nfts';
import {
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import BurnModal from '../BurnModal';

const config = getConfig()
const abis = getAbis()




const MAPPINGS = {
  0: "Common",
  1: "Uncommon",
  2: "Rare",
  3: "Mythic"
}
export default function LevelBox({ level, setApproveModalOpen }) {

  const walletInfo = useRecoilValue(getWalletState);
  const [nftRecoil, setNftRecoil] = useRecoilState(walletState)
  const nfts = useRecoilValue(getNftsState);
  const [selectedNft, setSelectedNft] = useState(null);
  const [recoilnfts, setRecoilNfts] = useRecoilState(nftsState);
  const [burnModalOpen, setBurnModalOpen] = useState(false);
  const [burningOption, setBurningOption] = useState(5);



  async function upgrade(tokenId) {
    let price = config.levelOneUpgradePrice

    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(abis["staking"], config["stake"])
    const erc20_contract = new web3.eth.Contract(abis["kril"], config["krill"])
    const kril_balance = await erc20_contract.methods.balanceOf(walletInfo.account).call();
    const allowance = await erc20_contract.methods.allowance(walletInfo.account, config["stake"]).call();
    const it_bal = normalizeBalance(kril_balance);
    const it_allowance = normalizeBalance(allowance);



    if (it_allowance >= it_bal) {
      if (it_bal < normalizeBalance(price)) {
        toast.error("Insufficient Allowance")
        setApproveModalOpen(true)
        return
      }


      const upgradePromise = contract.methods.upgradeNFT(tokenId).send({ from: walletInfo.account })

      await toast.promise(upgradePromise, {
        loading: 'Upgrading Level',
        success: "Successfully Upgraded",
        error: "Error",
      })
      const account = walletInfo.account;
      async function levelFetcher() {
        return await fetchNfts(account).then(async (data) => {
          console.log(data)
          const levels = [];
          for (let i = 0; i < data.length; i++) {
            const nft = data[i];
            console.log("nfy", nft)
            const level = await getNftsLevel(nft);
            levels.push({ "id": parseInt(nft), "level": parseInt(level) })
          }
          setRecoilNfts(levels)

        })
      }
      const promise = levelFetcher();
      toast.promise(promise, {
        loading: 'Fetching NFTS',
        success: "Success",
        error: "Eccor",
      })

    }
    else {
      toast.error("Insufficient Allowance")
      setApproveModalOpen(true)
    }



  }


  return (

    <div className="md:max-w-[600px] md:mx-auto xl:w-[46%]">
      <div className="wallet-info flex flex-col gap-4 p-[22px] xl:mt-0">
        <div>
          <h1>{MAPPINGS[level]}</h1>
        </div>
      </div>
      <div className="wallet-btns-container">

        {!walletInfo.isConnected &&

          <LevelConnect />

        }
        {walletInfo.isConnected &&
          <>


            <div className="pt-8">
              <div className="images min-h-[420px] max-h-[420px] pl-[3%] flex flex-wrap content-start gap-[5%] overflow-y-auto">
                {nfts.map((nft) => {
                  

                  if (nft.level == level) {
                    return (
                      <div className="image w-[45%] mb-[5%]" key={nft.id} onClick={() => {
                        if (nft.id === selectedNft) {
                          setSelectedNft(null)
                        }
                        else {
                          setSelectedNft(nft.id)
                        }

                      }}>
                        {nft.level == 2 ? <BurnModal tokenToUpgrade={selectedNft} mode={burningOption} isOpen={burnModalOpen} setIsOpen={setBurnModalOpen} setApproveModalOpen={setApproveModalOpen} /> : ""}
                        <img src={`${getBaseURIBYLevel(nft.level)}${nft.id}.png`} alt="" className={`${selectedNft === nft.id ? "image-active" : ""} w-[100%] h-[35vw] max-h-[200px] inline sm:max-h-[250px] md:max-h-[200px]`} />
                        <p className="image-caption">Psyco Susi #{nft.id}</p>
                      </div>

                    )
                  }
                })}
              </div>






            </div>
            {level < 2 &&
              <div className="wallet-btns-container-bottom flex flex-col gap-2 md:flex-row md:justify-end">
                <button className="btn md:w-[150px] md:mr-3" onClick={() => {
                  upgrade(selectedNft)
                }} disabled={selectedNft == null}>Upgrade</button>

              </div>
            }
            {level === 2 &&
              <div >

                <div className="wallet-btns-container-bottom flex flex-col gap-2 md:flex-row md:justify-end">
                  <button className="btn w-full " disabled={selectedNft == null} onClick={() => {
                    setBurningOption(5)
                    setBurnModalOpen(true);
                  }} >Upgrade (99 Kril + 5 Burn)</button>
                </div>
                <div className="wallet-btns-container-bottom flex flex-col gap-2 md:flex-row md:justify-end">
                  <button className="btn w-full" disabled={selectedNft == null} onClick={() => {
                    setBurningOption(2)
                    setBurnModalOpen(true);
                  }} >Upgrade (333 Kril + 2 Burn)</button>
                </div>
              </div>

            }
            {level === 3 &&

              <div className="wallet-btns-container-bottom flex flex-col gap-2 md:flex-row md:justify-end">
                <button className="btn md:w-[150px] md:mr-3" disabled={true}  >Upgrade</button>

              </div>
            }
          </>
        }

      </div>
    </div>
  )
}
