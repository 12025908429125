import './App.css';
import Header from './components/Header';
import { getNftsLevel } from './helpers';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import getWalletState from './selectors/wallets';
import toast, { Toaster } from "react-hot-toast"
import { fetchNfts } from './helpers';
import nftsState from './atoms/nfts';
import LevelBox from './components/levels/LevelBox';
import { getConfig,getAbis} from './helpers';
import krilBalState from './atoms/krilbal';
import Web3 from 'web3';
import ApproveModal from './components/ApproveModal';

const config = getConfig();
const abis = getAbis();

function App() {


  const walletInfo = useRecoilValue(getWalletState);
  const [nfts, setNfts] = useRecoilState(nftsState);
  const [krilBalance,setKrillBalance] = useRecoilState(krilBalState);
  const [approveModalOpen,setApproveModalOpen] = useState(false);



  useEffect(()=>{
    setInterval(()=>{
      if(walletInfo.isConnected){
        async function krilBalUpdater(){
          const web3 = new Web3(window.ethereum);
          const krilContract = new web3.eth.Contract(abis.kril,config.krill);
          const bal = await krilContract.methods.balanceOf(walletInfo.account).call();
          setKrillBalance(bal)
        }
        krilBalUpdater();
      }
    },2000)
  },[walletInfo])
  





  useEffect(() => {
    if (walletInfo.isConnected) {
      const web3 = new Web3(window.ethereum);
      const stakingContract = new web3.eth.Contract(abis.staking,config.stake);
      const account = walletInfo.account;

      async function levelFetcher() {
        return await fetchNfts(account).then(async (data) => {
          const levels = [];
          for (let i = 0; i < data.length; i++) {
            const nft = data[i];
            console.log("nfy", nft)
            const level = await getNftsLevel(nft);
            const isStaked = await stakingContract.methods.Vault(nft).call();
            console.log(`${nft} : stake status : ${isStaked.isStaked}`)

            levels.push({ "id": nft, "level": level,"isStaked":false })
          }
          setNfts(levels)

        })
      }

      const promise = levelFetcher();
      toast.promise(promise, {
        loading: 'Fetching NFTS',
        success: "Success",
        error: "Eccor",
      })


    }




  }, [walletInfo])



  return (
    <div className="px-4 mt-[20vh] mb-4 md:px-[2rem] md:mb-[4rem] xl:max-w-[1250px] xl:mx-auto" >
      <Header />
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <ApproveModal isOpen={approveModalOpen} setIsOpen={setApproveModalOpen} />

      <main className="md:relative md:top-[55px] md:px-[1.5rem] mb-[2rem] md:mb-[5rem]">
        <div className="w-full xl:flex xl:items-center xl:flex-wrap gap-[55px]">
          {/* <Common />
          <Uncommon />
          <Rare />
          <Mythic /> */}
          <LevelBox level={0} setApproveModalOpen={setApproveModalOpen}/>
          <LevelBox level={1} setApproveModalOpen={setApproveModalOpen}/>
          <LevelBox level={2} setApproveModalOpen={setApproveModalOpen}/>
          <LevelBox level={3} setApproveModalOpen={setApproveModalOpen}/>
        </div>
      </main>
    </div>
  );
}

export default App;
