import React from 'react'
import { useRecoilState} from 'recoil';
import { walletState } from '../atoms/wallets';
import Web3 from "web3";
import Web3Modal from "web3modal";




export default function LevelConnect() {
    const [walletInfo, setWalletInfo] = useRecoilState(walletState);
    async function connect() {
        const providerOptions = {
            /* See Provider Options Section */
        };

        const web3Modal = new Web3Modal({
            network: "mainnet", // optional
            cacheProvider: true, // optional
            providerOptions // required
        });

        const provider = await web3Modal.connect();

        const web3 = new Web3(provider);
        const accounts = await web3.eth.getAccounts();
        console.log("connected")
        setWalletInfo({ "account": accounts[0], "isConnected": true })
    }
    return (
        <div className="wallet-btns-container-top">
            <button className="btn relative top-[50%] left-[50%] ml-[-85px] mt-[30px]" onClick={connect}>{walletInfo.isConnected ? walletInfo.account : "Connect Wallet"}</button>
        </div>
    )
}
