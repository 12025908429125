
import {

    atom,

} from 'recoil';

const nftsState = atom({
    key: 'nfts', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
});

export default nftsState;