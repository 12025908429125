import React, { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil';
import { walletState } from '../atoms/wallets';
import Web3 from "web3";
import Web3Modal from "web3modal";
import BurningIncinator from './BurningIncinator';
import { trimWalletAddress, getConfig, getAbis, normalizeBalance } from '../helpers';

import getKrilBal from '../selectors/krilbal';

const config = getConfig();
const abis = getAbis();
export default function Header() {
  const [walletInfo, setWalletInfo] = useRecoilState(walletState);
  const krilBal = useRecoilValue(getKrilBal);
  const [isOpen,setIsOpen] = useState(false);
  
  async function connect() {
    const providerOptions = {
      /* See Provider Options Section */
    };
    const web3Modal = new Web3Modal({
      network: "goerli", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    console.log(web3)
    const accounts = await web3.eth.getAccounts();
    const account = accounts[0]
    //const account = "0x71D31B260f8664146C738d1f7747C55312375c0C"
    setWalletInfo({ "account": account, "isConnected": true })
  }

  return (
    <div className="flex flex-col gap-4 md:float-right mt-[-80px] md:mr-[45px] md:gap-2">

      <button className="btn w-[100%]" onClick={connect}>{walletInfo.isConnected ? `${trimWalletAddress(walletInfo.account)} (${normalizeBalance(krilBal)})` : "Connect Wallet"}</button>
      {walletInfo.account &&
        <>
         <button className="btn rounded-xl w-[100%]" onClick={()=>{
          setIsOpen(true);
         }}>Burning Incinerator</button>
         <BurningIncinator mode={100} isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
       
      }


    </div>
  )
}
